export const comments = {
  ja: {
    score: {
      up: `生活習慣スコアは3月より%d点上がりました。年度始めに良いスタートが切れていますね。<br>
      5月12日から<b>Walk チーム対抗戦 2022 1st</b>が始まります。<br>
      メンバーを集めて、チームを創り、みんなで楽しく歩きましょう♪<br>
      参加登録は5月11日まで！`,
      down: `生活習慣スコアは3月より%d点下がりました。分類別評価でどの項目に変化があったのか見てみましょう。<br>
      5月12日から<b>Walk チーム対抗戦 2022 1st</b>が始まります。<br>
      メンバーを集めて、チームを創り、みんなで楽しく歩きましょう♪<br>
      参加登録は5月11日まで！`,
      equal100: `生活習慣スコアは3月に引き続き<span class="good">満点</span>です。年度始めの慌ただしい時期に大変素晴らしいです！<br>
      5月12日から<b>Walk チーム対抗戦 2022 1st</b>が始まります。<br>
      メンバーを集めて、チームを創り、みんなで楽しく歩きましょう♪<br>
      参加登録は5月11日まで！`,
      equal: `生活習慣スコアは3月と同じ点数です。分類別評価で、項目別に振り返ってみましょう。<br>
      5月12日から<b>Walk チーム対抗戦 2022 1st</b>が始まります。<br>
      メンバーを集めて、チームを創り、みんなで楽しく歩きましょう♪<br>
      参加登録は5月11日まで！`,
    },
    steps: {
      up: `歩数スコアは3月より%d点上がりました。<br>
      歩く時は３つのポイントを意識しましょう。<br>
      <b>①姿勢  ②大股歩き  ③早歩き</b>`,
      down: `歩数スコアは3月より%d点下がりました。<br>
      歩く時は３つのポイントを意識しましょう。<br>
      <b>①姿勢  ②大股歩き  ③早歩き</b>`,
      equal: `歩数スコアは3月と同じです。<br>
      歩く時は３つのポイントを意識しましょう。<br>
      <b>①姿勢  ②大股歩き  ③早歩き</b>`,
    },
    exercise: {
      up: `運動のスコアは3月より%d点上がりました。<br>
      ウォーキングやサイクリングなどの有酸素運動をして、
      幸せホルモンと呼ばれる「セロトニン」を増やそう。
      `,
      down: `運動のスコアは3月より%d点下がりました。<br>
      ウォーキングやサイクリングなどの有酸素運動をして、
      幸せホルモンと呼ばれる「セロトニン」を増やそう。
      `,
      equal: `運動のスコアは3月と同じです。<br>
      ウォーキングやサイクリングなどの有酸素運動をして、
      幸せホルモンと呼ばれる「セロトニン」を増やそう。
      `,
    },
    meal: {
      up: `食事のスコアは3月より%d点上がりました。<br>
      朝食は1日のはじまりの大事なスイッチ！<br>
      エネルギー源となるブドウ糖をしっかり補給し、脳を目覚めさせましょう。
      `,
      down: `食事のスコアは3月より%d点下がりました。<br>
      朝食は1日のはじまりの大事なスイッチ！<br>
      エネルギー源となるブドウ糖をしっかり補給し、脳を目覚めさせましょう。
      `,
      equal: `食事のスコアは3月と同じです。<br>
      朝食は1日のはじまりの大事なスイッチ！<br>
      エネルギー源となるブドウ糖をしっかり補給し、脳を目覚めさせましょう。
      `,
    },
    drinking: {
      up: `飲酒のスコアは3月より%d点上がりました。<br>
      大量の飲酒は、うつ病や認知症の原因になります。<br>
      飲みすぎ注意！`,
      down: `飲酒のスコアは3月より%d点下がりました。<br>
      大量の飲酒は、うつ病や認知症の原因になります。<br>
      飲みすぎ注意！`,
      equal: `飲酒のスコアは3月と同じです。<br>
      大量の飲酒は、うつ病や認知症の原因になります。<br>
      飲みすぎ注意！`,
    },
    sleep: {
      up: `睡眠のスコアは3月より%d点上がりました。<br>
      質の良い睡眠は、パフォーマンスや免疫力の向上につながります。<br>
      <a href="https://go.andwell.jp/library/89480a47-000c-11ec-a3b7-0af363eb5062">こちらの動画</a>では、良質な睡眠のポイントやぐっすりストレッチを紹介。`,
      down: `睡眠のスコアは3月より%d点下がりました。<br>
      質の良い睡眠は、パフォーマンスや免疫力の向上につながります。<br>
      <a href="https://go.andwell.jp/library/89480a47-000c-11ec-a3b7-0af363eb5062">こちらの動画</a>では、良質な睡眠のポイントやぐっすりストレッチを紹介。`,
      equal: `睡眠のスコアは3月と同じです。<br>
      質の良い睡眠は、パフォーマンスや免疫力の向上につながります。<br>
      <a href="https://go.andwell.jp/library/89480a47-000c-11ec-a3b7-0af363eb5062">こちらの動画</a>では、良質な睡眠のポイントやぐっすりストレッチを紹介。`,
    },
    stress: {
      up: `ストレスのスコアは3月より%d点上がりました。<br>
      <a href="https://go.andwell.jp/library/2ac08e94-5e36-11ec-9ce8-0af363eb5062">こちらのコラム</a>でリラックス法を紹介しています。<br>
      ご自身に合うものを見つけて、ストレスと上手く付き合っていきましょう。`,
      down: `ストレスのスコアは3月より%d点下がりました。<br>
      <a href="https://go.andwell.jp/library/2ac08e94-5e36-11ec-9ce8-0af363eb5062">こちらのコラム</a>でリラックス法を紹介しています。<br>
      ご自身に合うものを見つけて、ストレスと上手く付き合っていきましょう。`,
      equal: `ストレスのスコアは3月と同じです。<br>
      <a href="https://go.andwell.jp/library/2ac08e94-5e36-11ec-9ce8-0af363eb5062">こちらのコラム</a>でリラックス法を紹介しています。<br>
      ご自身に合うものを見つけて、ストレスと上手く付き合っていきましょう。`,
    },
  },
  en: {
    score: {
      up: `Your lifestyle score has gone up %d pts since March. Your fiscal year is off to a great start. <br>
      The 1st Walk Team Competition 2022 will start on May 12. <br>
      Recruit members, make your team, and have fun walking together!<br>
      You can sign up until May 11!`,
      down: `Your lifestyle score has gone down %d pts since March. Use the categorized ratings to see what items have changed. <br>
      The 1st Walk Team Competition 2022 will start on May 12. <br>
      Recruit members, make your team, and have fun walking together!<br>
      You can sign up until May 11!`,
      equal100: `Your lifestyle score is the <span class="good">maximum</span> again, the same as March. You're doing fantastic during the hectic fiscal year's start! <br>
      The 1st Walk Team Competition 2022 will start on May 12. <br>
      Recruit members, make your team, and have fun walking together!<br>
      You can sign up until May 11!`,
      equal: `Your lifestyle score is the same as March. Use the categorized ratings to see what items have changed.<br>
      The 1st Walk Team Competition 2022 will start on May 12. <br>
      Recruit members, make your team, and have fun walking together!<br>
      You can sign up until May 11!`,
    },
    steps: {
      up: `Your steps score has gone up %d pts since March. <br>
      Keep these three things in mind when walking: <br>
      (1) Good posture (2) Taking big steps (3) Walking fast`,
      down: `Your steps score has gone down %d pts since March. <br>
      Keep these three things in mind when walking: <br>
      (1) Good posture (2) Taking big steps (3) Walking fast`,
      equal: `Your steps score is the same as March. <br>
      Keep these three things in mind when walking: <br>
      (1) Good posture (2) Taking big steps (3) Walking fast`,
    },
    exercise: {
      up: `Your exercise score has gone up %d pts since March. <br>
      Do cardio like walking or cycling to increase your serotonin, also known as the happiness hormone.`,
      down: `Your exercise score has gone down %d pts since March. <br>
      Do cardio like walking or cycling to increase your serotonin, also known as the happiness hormone.`,
      equal: `Your exercise score is the same as March <br>
      Do cardio like walking or cycling to increase your serotonin, also known as the happiness hormone.`,
    },
    meal: {
      up: `Your food score has gone up %d pts since March.<br>
      Breakfast is the key to getting your day started! <br>
      Wake up your brain by fueling it well with glucose, which it can use as an energy source.`,
      down: `Your food score has gone down %d pts since March.<br>
      Breakfast is the key to getting your day started! <br>
      Wake up your brain by fueling it well with glucose, which it can use as an energy source.`,
      equal: `Your food score  is the same as March.<br>
      Breakfast is the key to getting your day started! <br>
      Wake up your brain by fueling it well with glucose, which it can use as an energy source.`,
    },
    drinking: {
      up: `Your alcohol score has gone up %d pts since March. <br>
      Heavy drinking can cause depression and dementia. <br>
      Be careful not to drink too much!`,
      down: `Your alcohol score has gone down %d pts since March. <br>
      Heavy drinking can cause depression and dementia. <br>
      Be careful not to drink too much!`,
      equal: `Your alcohol score is the same as March. <br>
      Heavy drinking can cause depression and dementia. <br>
      Be careful not to drink too much!`,
    },
    sleep: {
      up: `Your sleep score has gone up %d pts since March. <br>
      Getting a good night's sleep can improve your performance and immunity. <br>
      Watch <a href="https://go.andwell.jp/library/89480a47-000c-11ec-a3b7-0af363eb5062">this video</a> to learn about the keys to sleeping well and see some great stretches you can do.`,
      down: `Your sleep score has gone down %d pts since March. <br>
      Getting a good night's sleep can improve your performance and immunity. <br>
      Watch <a href="https://go.andwell.jp/library/89480a47-000c-11ec-a3b7-0af363eb5062">this video</a> to learn about the keys to sleeping well and see some great stretches you can do.`,
      equal: `Your sleep score is the same as March. <br>
      Getting a good night's sleep can improve your performance and immunity. <br>
      Watch <a href="https://go.andwell.jp/library/89480a47-000c-11ec-a3b7-0af363eb5062">this video</a> to learn about the keys to sleeping well and see some great stretches you can do.`,
    },
    stress: {
      up: `Your stress score has gone up %d pts since March.<br>
      Read <a href="https://go.andwell.jp/library/2ac08e94-5e36-11ec-9ce8-0af363eb5062">this column</a> to learn about ways to relax. <br>
      Find a way to deal with your stress that suits you.`,
      down: `Your stress score has gone down %d pts since March.<br>
      Read <a href="https://go.andwell.jp/library/2ac08e94-5e36-11ec-9ce8-0af363eb5062">this column</a> to learn about ways to relax. <br>
      Find a way to deal with your stress that suits you.`,
      equal: `Your stress score is the same as March.<br>
      Read <a href="https://go.andwell.jp/library/2ac08e94-5e36-11ec-9ce8-0af363eb5062">this column</a> to learn about ways to relax. <br>
      Find a way to deal with your stress that suits you.`,
    },
  },
}
